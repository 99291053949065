<template>
  <div class="overflow-auto mt-3" id="table-container">
    <MDBTable hover class="align-middle">
      <thead class="table-light">
        <tr class="align-middle">
          <th class="sticky-top">
            <MDBCheckbox v-model="selectAll" @click="toggleSelectAll(selectAll)" />
          </th>
          <th class="sticky-top" scope="col">Thumbnail</th>
          <th class="sticky-top" scope="col">Title</th>
          <th class="sticky-top" scope="col">URL</th>
          <th class="sticky-top" scope="col">Asset ID</th>
          <th class="sticky-top" scope="col">Upload Date</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="isLoading">
          <tr>
            <td colspan="6" class="text-center">
              <div class="image-container d-flex justify-content-center align-items-center">
                <div class="loader"></div>
                <img src="@/assets/cs-loader.png" alt="Loading image">
              </div>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr v-if="!isLoading && copyList.length === 0">
            <td colspan="6" class="text-center">No copies found.</td>
          </tr>
          <template v-else>
            <tr v-for="(copy, index) in copyList" :key="index">
              <td>
                <MDBCheckbox v-model="copy.selected" @click="toggleSingle(copy.selected)" />
              </td>
              <td class="d-flex justify-content-center">
                <template v-if="hasThumbnail(copy.mediaUrl)">
                  <img :src="copy.thumbnail" :alt="copy.thumbnail" width="50" height="50" />
                </template>
                <template v-else>
                  <iframe width="50" height="50" :src="generateUrl(copy.mediaUrl)" controls="0" scrolling="no"
                    style="border: none; overflow: hidden" frameborder="0"></iframe>
                </template>
              </td>
              <td>{{ copy.title }}</td>
              <td>
                <a :href="copy.mediaUrl">{{ copy.mediaUrl }}</a>
              </td>
              <td>{{ copy.assetIdString }}</td>
              <td class="text-nowrap">{{ parseDateLong(copy.uploadDate) }}</td>
            </tr>
          </template>
        </template>
      </tbody>
    </MDBTable>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed, defineExpose } from "vue";
import { MDBTable, MDBCheckbox } from "mdb-vue-ui-kit";
import { GetCopies } from "../services/Copies/GetCopies";
import { GetCopiesTotalCount } from "../services/Copies/GetCopiesTotalCount";
import { parseDateLong } from "../helpers/parseDate";
import { useInfiniteScroll } from "@vueuse/core";
import axios from "axios";
import { useCopiesFilter } from "../store/copiesFilter";
import { storeToRefs } from "pinia";

const copiesFilterStore = useCopiesFilter();

const {
  selectedPlatformList,
  confirmedView,
  confirmedCollection,
  confirmedVerifiedUserValue,
  confirmedClaimIssuedValue,
  confirmedFairUseValue,
  confirmedForFurtherReviewValue,
  confirmedExternallyActionedValue,
  confirmedFalsePositiveValue,
  confirmedWhiteListedValue,
  filtersSet,
  copiesFilter,
} = storeToRefs(copiesFilterStore);

const copyList = ref([]);
const copyListTotalCount = ref(null);
const isLoading = ref(false);

const getCopies = async (lastItem, newFilter) => {
  isLoading.value = true;
  const params = {
    ...(lastItem ? { lastItem: lastItem } : {}),
    ...(filtersSet.value
      ? { filters: newFilter ? { ...newFilter } : { ...filtersSet.value } }
      : {}),
  };
  const response = await GetCopies(params);
  copyListTotalCount.value = await GetCopiesTotalCount(params);
  const newList = await Promise.all(
    response.map(async (list) => {
      let imgUrl = "";
      if (list.mediaUrl.includes("https://www.tiktok.com/")) {
        const tiktokResponse = await axios.get(
          `https://www.tiktok.com/oembed?url=${list.mediaUrl}`
        );
        imgUrl = tiktokResponse.data.thumbnail_url;
      }
      if (list.mediaUrl.includes("https://www.youtube.com/")) {
        imgUrl = getYoutubeThumbnail(list.mediaUrl);
      }
      if (list.mediaUrl.includes("https://www.dailymotion.com/")) {
        imgUrl = getDailyMotionThumbnail(list.mediaUrl);
      }
      if (list.mediaUrl.includes("https://vimeo.com/")) {
        imgUrl = getVimeoThumbnail(list.mediaUrl);
      }
      return {
        ...list,
        thumbnail: imgUrl,
        selected: selectAll.value ? selectAll.value : !!list.selected,
      };
    })
  );
  return newList;
};

watch(
  [
    selectedPlatformList,
    confirmedCollection,
    confirmedVerifiedUserValue,
    confirmedClaimIssuedValue,
    confirmedFairUseValue,
    confirmedForFurtherReviewValue,
    confirmedExternallyActionedValue,
    confirmedFalsePositiveValue,
    confirmedWhiteListedValue,
    confirmedView,
  ],
  async () => {
    copyList.value = [];
    copiesFilter.value = false;
    copyList.value = await getCopies("", filtersSet.value);
    isLoading.value = false;
  },
  { deep: true }
);

const setInitialCopies = async () => {
  copyList.value = [];
  selectAll.value = false;
  copyList.value = await getCopies();
  isLoading.value = false;
};

onMounted(() => {
  setInitialCopies();
  useInfiniteScroll(
    el,
    async () => {
      if (
        isLoading.value ||
        stopScrollLoad.value ||
        copyList.value.length === 0
      ) {
        return;
      }
      const currentArr = [...copyList.value];
      const lastItem = currentArr.pop();
      const response = await getCopies(lastItem.matchIdString);
      if (response.length === 0) {
        isLoading.value = false;
        stopScrollLoad.value = true;
        isLoading.value = false;
        return;
      }
      copyList.value = copyList.value.concat(response);
      isLoading.value = false;
    },
    { distance: 10 }
  );
});

const hasThumbnail = (url) => {
  return (
    url.includes("https://www.youtube.com/") ||
    url.includes("https://www.dailymotion.com/") ||
    url.includes("https://vimeo.com/") ||
    url.includes("https://www.tiktok.com/")
  );
};

const generateUrl = (url) => {
  if (url.includes("https://www.facebook.com/")) {
    return getFacebookVideoUrl(url);
  }
  if (url.includes("https://www.instagram.com/")) {
    return getInstagramVideoUrl(url);
  }
  return url;
};

const getFacebookVideoUrl = (url) => {
  const FB_PLUGIN_URL = "https://www.facebook.com/plugins/video.php";
  return `${FB_PLUGIN_URL}?href=${url}&width=100&show_text=false&height=100&appId`;
};

const getInstagramVideoUrl = (url) => {
  return `${url}/embed/`;
};

const getYoutubeThumbnail = (url) => {
  const videoId = getYoutubeVideoId(url);
  return `https://img.youtube.com/vi/${videoId}/0.jpg`;
};

const getYoutubeVideoId = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url?.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
};

const getDailyMotionThumbnail = (url) => {
  const id = getDailyMotionId(url);
  return `https://www.dailymotion.com/thumbnail/video/${id}`;
};

const getDailyMotionId = (url) => {
  var m = url.match(
    /^.+dailymotion.com\/(video|hub)\/([^_]+)[^#]*(#video=([^_&]+))?/
  );
  if (m !== null) {
    if (m[4] !== undefined) {
      return m[4];
    }
    return m[2];
  }
  return null;
};

const getVimeoThumbnail = (url) => {
  const id = getVimeoId(url);
  return `https://vumbnail.com/${id}.jpg`;
};

const getVimeoId = (url) => {
  const regExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
  const match = url.match(regExp);
  if (match !== null) {
    return match[2];
  }
  return null;
};

const el = ref();
const stopScrollLoad = ref(false);
const selectAll = ref(false);

const toggleSelectAll = (selectVal) => {
  copyList.value = copyList.value.map((list) => {
    return {
      ...list,
      selected: !selectVal,
    };
  });
};

const toggleSingle = (selection) => {
  const currentCount = selection
    ? selectedList.value - 1
    : selectedList.value + 1;
  selectAll.value = currentCount === copyList.value.length;
};

const unSelectedList = computed(() => {
  return copyList.value.filter((list) => !list.selected);
});

const selectedList = computed(() => {
  return copyList.value.filter((list) => list.selected);
});

defineExpose({
  selectAll,
  selectedList,
  unSelectedList,
  setInitialCopies,
  copyListTotalCount,
});
</script>

<style scoped>
.table-container thead th {
  position: sticky;
  top: 0;
  background-color: var(--accent);
  color: white;
  z-index: 1;
}

iframe {
  pointer-events: none;
}

th {
  text-transform: uppercase;
}

tr {
  cursor: default;
}

#table-container {
  flex: 1 1 auto;
  height: 55vh;
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-container .loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 40px;
  height: 40px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top-color: rgb(153, 153, 153);
  border-radius: 50%;
  animation: BorderSpin 1s linear infinite;
}

.image-container img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

@keyframes BorderSpin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
