<template>
  <MDBModal v-model="collectionModalOpen" tabindex="-1">
    <MDBModalHeader class="py-3 px-4">
      <MDBModalTitle class="fw-bold"> Select Collection </MDBModalTitle>
    </MDBModalHeader>
    <MDBModalBody class="p-4">
      <MDBSelect v-if="collectionModalOpen" v-model:options="collectionList" v-model:selected="selectedCollection"
        tabindex="1" filter placeholder="Please Select Collection" />
    </MDBModalBody>
    <MDBModalFooter class="py-3 gap-2">
      <MDBBtn class="fw-bold" size="sm" @click="collectionModalOpen = false">Close</MDBBtn>
      <MDBBtn class="fw-bold solid" outline="primary" @click="save" :disabled="!selectedCollection">
        Save changes
      </MDBBtn>
    </MDBModalFooter>
  </MDBModal>
</template>

<script setup>
import {
  MDBModal,
  MDBModalTitle,
  MDBModalHeader,
  MDBBtn,
  MDBModalBody,
  MDBModalFooter,
  MDBSelect,
} from "mdb-vue-ui-kit";
import { storeToRefs } from "pinia";
import { useCopiesFilter } from "../store/copiesFilter";
import { watch } from "vue";
import { GetCollectionsAll } from "../services/Collections/GetCollectionsAll";

const copiesFilterStore = useCopiesFilter();

const {
  collectionModalOpen,
  collectionList,
  selectedCollection,
  selectedCollectionName,
  confirmedCollection,
} = storeToRefs(copiesFilterStore);

watch(collectionModalOpen, async (newVal) => {
  if (newVal) {
    const response = await GetCollectionsAll();
    setCollectionList(response);
  }
});

const setCollectionList = (response) => {
  const newList = response.map((res) => {
    return {
      text: res.title,
      value: res.collectionIdString,
    };
  });
  collectionList.value = [
    {
      text: "Select Collection",
      value: "",
      selected: true,
      hidden: true,
      disabled: true,
    },
    ...newList,
  ];
};

const save = () => {
  const newCollection = selectedCollection.value;
  confirmedCollection.value = newCollection;

  const findCollection = collectionList.value.find(
    (collection) => collection.value === newCollection
  );
  selectedCollectionName.value = findCollection.text;

  collectionModalOpen.value = false;
  selectedCollection.value = null;
};
</script>

<style scoped>
.modal-body {
  overflow-y: auto;
  position: static;
}

.items {
  cursor: pointer;
}

.solid {
  background-color: var(--primary);
  color: white;
}

.solid:hover {
  background-color: var(--accent);
  border-color: var(--accent);
}
</style>
