<template>
  <MDBModal v-model="platformModalOpen" tabindex="-1">
    <MDBModalHeader class="py-3 px-4">
      <MDBModalTitle class="fw-bold"> Choose Platform </MDBModalTitle>
    </MDBModalHeader>
    <MDBModalBody class="p-4">
      <MDBSelect v-model:options="platformList" v-model:selected="selectedList" tabindex="1" multiple filter
        placeholder="Please select platform" optionsSelectedLabel="platforms selected" />
    </MDBModalBody>
    <MDBModalFooter class="py-3 gap-2">
      <MDBBtn class="fw-bold" size="sm" @click="platformModalOpen = false">Close</MDBBtn>
      <MDBBtn size="sm" class="solid fw-bold" outline="primary" @click="save">
        Save changes
      </MDBBtn>
    </MDBModalFooter>
  </MDBModal>
</template>

<script setup>
import {
  MDBModal,
  MDBModalTitle,
  MDBModalHeader,
  MDBBtn,
  MDBModalBody,
  MDBModalFooter,
  MDBSelect,
} from "mdb-vue-ui-kit";
import { storeToRefs } from "pinia";
import { useCopiesFilter } from "../store/copiesFilter";
import { ref, watch } from "vue";

const copiesFilterStore = useCopiesFilter();

const {
  platformModalOpen,
  platformList,
  selectedPlatformList,
  selectedPlatformNameList,
} = storeToRefs(copiesFilterStore);

const selectedList = ref([]);
watch(platformModalOpen, (newVal) => {
  if (newVal) {
    selectedList.value = selectedPlatformList.value.join();
  }
});

const save = () => {
  const selectedListArray = selectedList.value.split(",");
  selectedPlatformList.value = [
    ...selectedListArray.map((list) => Number(list)),
  ];
  selectedPlatformNameList.value = selectedListArray.map((value) => {
    const selectedObject = platformList.value.find(
      (obj) => String(obj.value) === value
    );
    return selectedObject.text;
  });
  platformModalOpen.value = false;
};
</script>

<style scoped>
.modal-body {
  overflow-y: auto;
  position: static;
}

.items {
  cursor: pointer;
}

.solid {
  background-color: var(--primary);
  border-color: var(--primary);
  color: white;
}

.solid:hover {
  background-color: var(--accent);
  border-color: var(--accent);
}
</style>
