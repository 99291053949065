<template>
  <MDBModal id="ViewsModalComponent" v-model="viewsModal">
    <MDBModalHeader class="py-3 px-4">
      <MDBModalTitle class="fw-bold"> Views </MDBModalTitle>
    </MDBModalHeader>
    <MDBModalBody class="p-4">
      <MDBBtnGroup aria-label="Range" class="mb-3">
        <MDBBtn outline="primary" :class="[viewsIsMoreThan ? 'solid fw-bold' : 'outlined fw-bold']" @click="viewsIsMoreThan = true">
          More than
        </MDBBtn>
        <MDBBtn outline="primary" :class="[viewsIsLessThan ? 'solid fw-bold' : 'outlined fw-bold']" @click="viewsIsLessThan = true">
          Less than
        </MDBBtn>
        <MDBBtn outline="primary" :class="[viewsIsRange ? 'solid fw-bold' : 'outlined fw-bold']" @click="viewsIsRange = true">
          Range
        </MDBBtn>
      </MDBBtnGroup>
      <MDBInput min="1" type="number" label="Enter Value" v-model="viewsMinValue" />
      <MDBInput min="1" type="number" class="mt-3" v-if="viewsIsRange" label="Enter Max Value"
        v-model="viewsMaxValue" />
    </MDBModalBody>
    <MDBModalFooter class="py-3 gap-2">
      <MDBBtn class="fw-bold" size="sm" @click="viewsModal = false">Close</MDBBtn>
      <MDBBtn class="solid fw-bold" outline="primary" :disabled="!viewsMinValue" @click="save">
        Save changes
      </MDBBtn>
    </MDBModalFooter>
  </MDBModal>
</template>

<script setup>
import {
  MDBModal,
  MDBModalTitle,
  MDBModalHeader,
  MDBBtnGroup,
  MDBBtn,
  MDBInput,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-vue-ui-kit";
import { useCopiesFilter } from "../store/copiesFilter";
import { storeToRefs } from "pinia";
import { watch } from "vue";

const copiesFilterStore = useCopiesFilter();

const {
  viewsModal,
  viewsIsMoreThan,
  viewsIsLessThan,
  viewsIsRange,
  viewsMinValue,
  viewsMaxValue,
  confirmedView,
} = storeToRefs(copiesFilterStore);

watch(viewsIsMoreThan, (newMoreThan) => {
  if (newMoreThan) {
    viewsIsLessThan.value = false;
    viewsIsRange.value = false;
    viewsMaxValue.value = null;
  }
});

watch(viewsIsLessThan, (newLessThan) => {
  if (newLessThan) {
    viewsIsMoreThan.value = false;
    viewsIsRange.value = false;
    viewsMaxValue.value = null;
  }
});

watch(viewsIsRange, (newRange) => {
  if (newRange) {
    viewsMaxValue.value = null;
    viewsIsMoreThan.value = false;
    viewsIsLessThan.value = false;
  }
});

const save = () => {
  confirmedView.value = {
    minValue: viewsMinValue.value,
    maxValue: viewsMaxValue.value,
    isMoreThan: viewsIsMoreThan.value,
    isLessThan: viewsIsLessThan.value,
    isRange: viewsIsRange.value,
  };
  viewsModal.value = false;
};
</script>

<style scoped>
#ViewsModalComponent .solid {
  background-color: var(--primary);
  border-color: var(--primary);
  color: white;
}

#ViewsModalComponent .solid:hover {
  background-color: var(--primary);
  border-color: var(--primary);
}

#ViewsModalComponent .outlined {
  color: var(--primary);
  border-color: var(--primary);
}

#ViewsModalComponent .outlined:hover {
  background-color: var(--primary);
  color: white;
}
</style>
