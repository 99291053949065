<template>
  <MDBModal v-model="falsePositiveModal">
    <MDBModalHeader class="py-3 px-4">
      <MDBModalTitle class="fw-bold"> Labeled as False Positive </MDBModalTitle>
    </MDBModalHeader>
    <MDBModalBody class="p-4">
      <template v-for="(item, index) in selectionList" :key="index">
        <MDBRadio :label="item.label" :formCheck="false" name="label-as-false-positive" v-model="falsePositiveValue"
          :value="item.value" />
      </template>
    </MDBModalBody>
    <MDBModalFooter class="py-3 gap-2">
      <MDBBtn class="fw-bold" size="sm" @click="falsePositiveModal = false">Close</MDBBtn>
      <MDBBtn size="sm" class="fw-bold solid" outline="primary" @click="save">
        Save changes
      </MDBBtn>
    </MDBModalFooter>
  </MDBModal>
</template>

<script setup>
import {
  MDBModal,
  MDBModalTitle,
  MDBModalHeader,
  MDBBtn,
  MDBRadio,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-vue-ui-kit";
import { useCopiesFilter } from "../store/copiesFilter";
import { storeToRefs } from "pinia";
import { watch, ref } from "vue";

const copiesFilterStore = useCopiesFilter();

const {
  falsePositiveModal,
  falsePositiveValue,
  falsePositiveValueName,
  confirmedFalsePositiveValue,
} = storeToRefs(copiesFilterStore);

const selectionList = ref([
  {
    label: "None",
    value: "0",
  },
  {
    label: "Show Only",
    value: "1",
  },
  {
    label: "Exclude",
    value: "2",
  },
]);

const save = () => {
  confirmedFalsePositiveValue.value = falsePositiveValue.value;
  const findCollection = selectionList.value.find(
    (list) => list.value === falsePositiveValue.value
  );
  falsePositiveValueName.value = findCollection.label;

  falsePositiveModal.value = false;
  falsePositiveValue.value = "0";
};

watch(falsePositiveModal, (newVal) => {
  if (newVal) {
    falsePositiveValue.value = confirmedFalsePositiveValue.value;
  }
});
</script>

<style scoped>
.solid {
  background-color: var(--primary);
  border-color: var(--primary);
  color: white;
}

.solid:hover {
  background-color: var(--accent);
  border-color: var(--accent);
}
</style>
